const toggleCalendarInputActive: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');

    parent.classList.toggle('active');
  } catch (error) {
    console.error(error);
  }
};

const clearCalendarInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setCalendarInputError: (calendarInputID: string) => void = calendarInputID => {
  try {
    const calendarInput: any = document.getElementById(calendarInputID);
    const parent: any = calendarInput?.closest('.calendarInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const handleCalendarBackgroundClick: (event: any) => void = event => {
  try {
    toggleCalendarInputActive(event);
    validateCalendarInput(event);
  } catch (error) {
    console.error(error);
  }
};

const validateCalendarInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const valueInput: any = parent?.getElementsByTagName('input')[0];
    const value: string = valueInput?.value || '';
    const isRequired: boolean = parent?.classList.contains('required');

    parent.classList.remove('invalid');

    if (isRequired) {
      const isValidCalendarValue: boolean = getIsValidCalendarValue(value);

      if (!isValidCalendarValue) parent.classList.add('invalid');

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const getIsValidCalendarValue: (value: string) => boolean = value => {
  try {
    if (value === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

interface CurrentDateObject {
  year: number;
  month: number;
  day: number;
  dateString: string;
}

const getCurrentDate: () => CurrentDateObject | null = () => {
  try {
    const currentDate: any = new Date();
    const currentYear: number = currentDate.getFullYear();
    const currentMonth: number = currentDate.getMonth() + 1;
    const currentDay: number = currentDate.getDate();
    const currentDateString: string = `${currentYear}-${currentMonth}-${currentDay}`;

    return {
      year: currentYear,
      month: currentMonth,
      day: currentDay,
      dateString: currentDateString,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getCalendarYearContents: (calendarYearDelta: number) => string = calendarYearDelta => {
  try {
    const itemCount: number = 15;
    const currentDate: CurrentDateObject | null = getCurrentDate();
    const currentYear: number = currentDate?.year || 0;
    const desiredYear: number = currentYear - calendarYearDelta * itemCount;
    var contents: string = '';

    for (let i = itemCount - 1; i >= 0; i--) {
      const year: number = desiredYear - i;

      contents =
        contents +
        `<div data-value="${year}" class="calendarYearItem" onclick="setCalendarActiveYear(event)">${year}</div>`;
    }

    return contents;
  } catch (error) {
    console.error(error);
    return '';
  }
};

const getCalendarDaysContents: (numberOfDays: number, firstDayOfMonth: number) => string = (
  numberOfDays,
  firstDayOfMonth
) => {
  try {
    const daysInWeek: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    var contents: string = '';

    for (let i = 0; i < daysInWeek.length; i++) {
      const day: string = daysInWeek[i];

      contents += `<div class="calendarDayItem heading">${day}</div>`;
    }

    for (let i = 0; i < firstDayOfMonth; i++) {
      contents += `<div class="calendarDayItem"></div>`;
    }

    for (let i = 1; i <= numberOfDays; i++) {
      contents += `<div data-value="${i}" class="calendarDayItem" onclick="setCalendarActiveDay(event)">${i}</div>`;
    }

    return contents;
  } catch (error) {
    console.error(error);
    return '';
  }
};

const setCalendarActiveYear: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const inputs: any = parent?.getElementsByClassName('calendarYearItem');
    const holdingValue: any = parent?.getElementsByClassName('yearInput')[0];
    const value = input?.dataset?.value || '';

    for (let i = 0; i < inputs.length; i++) {
      const input: any = inputs[i];

      input.classList.remove('active');
    }

    input.classList.add('active');
    holdingValue.value = value;
    moveToCalendarMonths(event, value);
  } catch (error) {
    console.error(error);
  }
};

const setCalendarActiveMonth: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const inputs: any = parent?.getElementsByClassName('calendarMonthItem');
    const holdingValue: any = parent?.getElementsByClassName('monthInput')[0];
    const monthNameInput: any = parent?.getElementsByClassName('monthNameInput')[0];
    const value = input?.dataset?.value || '';
    const index: number = parseInt(input?.dataset?.index);

    for (let i = 0; i < inputs.length; i++) {
      const input: any = inputs[i];

      input.classList.remove('active');
    }

    input.classList.add('active');
    holdingValue.value = index;
    monthNameInput.value = value;
    moveToCalendarDays(event, value);
  } catch (error) {
    console.error(error);
  }
};

const setCalendarActiveDay: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const inputs: any = parent?.getElementsByClassName('calendarDayItem');
    const holdingValue: any = parent?.getElementsByClassName('dayInput')[0];
    const value = input?.dataset?.value || '';

    for (let i = 0; i < inputs.length; i++) {
      const input: any = inputs[i];

      input.classList.remove('active');
    }

    input.classList.add('active');
    holdingValue.value = value;
    setCalendarActiveDate(event);
    toggleCalendarInputActive(event);
  } catch (error) {
    console.error(error);
  }
};

const setCalendarActiveDate: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearInput: any = parent?.getElementsByClassName('yearInput')[0];
    const monthNameInput: any = parent?.getElementsByClassName('monthNameInput')[0];
    const dayInput: any = parent?.getElementsByClassName('dayInput')[0];
    const value: string = `${dayInput.value} ${monthNameInput.value} ${yearInput.value}`;
    const dateValue: any = parent?.getElementsByTagName('input')[0];
    const placeholderInput: any = parent?.getElementsByClassName('currentValue')[0];

    dateValue.value = value.replace(/\s+/g, '-');
    placeholderInput.innerText = value;
  } catch (error) {
    console.error(error);
  }
};

const moveToCalendarMonths: (event: any, year: string) => void = (event, year) => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const monthContainer: any = parent?.getElementsByClassName('month')[0];
    const chosenYear_month: any = monthContainer?.getElementsByClassName('chosenYear')[0];
    const dayContainer: any = parent?.getElementsByClassName('day')[0];
    const chosenYear_day: any = dayContainer?.getElementsByClassName('chosenYear')[0];

    chosenYear_month.innerText = year;
    chosenYear_day.innerText = year;
    goToCalendarMonths(event);
  } catch (error) {
    console.error(error);
  }
};

const moveToCalendarDays: (event: any, month: string) => void = (event, month) => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const daysContainer: any = parent?.getElementsByClassName('day')[0];
    const chosenMonth: any = daysContainer?.getElementsByClassName('chosenMonth')[0];
    const yearInput: any = parent?.getElementsByClassName('yearInput')[0];
    const monthInput: any = parent?.getElementsByClassName('monthInput')[0];
    const days: any = parent?.getElementsByClassName('days')[0];
    const daysInMonth: number = getNumberOfDaysInMonthFromMonthAndYear(
      parseInt(monthInput.value),
      parseInt(yearInput.value)
    );
    const firstDayOfMonth: number = getFirstDayOfMonthFromMonthAndYear(
      parseInt(monthInput.value),
      parseInt(yearInput.value)
    );
    const contents: string = getCalendarDaysContents(daysInMonth, firstDayOfMonth);

    chosenMonth.innerText = month;
    days.innerHTML = contents;
    goToCalendarDays(event);
  } catch (error) {
    console.error(error);
  }
};

const goToCalendarYears: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearContainer: any = parent?.getElementsByClassName('year')[0];
    const monthContainer: any = parent?.getElementsByClassName('month')[0];
    const dayContainer: any = parent?.getElementsByClassName('day')[0];

    yearContainer.classList.add('active');
    monthContainer.classList.remove('active');
    dayContainer.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

const goToCalendarMonths: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearContainer: any = parent?.getElementsByClassName('year')[0];
    const monthContainer: any = parent?.getElementsByClassName('month')[0];
    const dayContainer: any = parent?.getElementsByClassName('day')[0];

    yearContainer.classList.remove('active');
    monthContainer.classList.add('active');
    dayContainer.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

const goToCalendarDays: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearContainer: any = parent?.getElementsByClassName('year')[0];
    const monthContainer: any = parent?.getElementsByClassName('month')[0];
    const dayContainer: any = parent?.getElementsByClassName('day')[0];

    yearContainer.classList.remove('active');
    monthContainer.classList.remove('active');
    dayContainer.classList.add('active');
  } catch (error) {
    console.error(error);
  }
};

const setCalendarYearByDeltaAndElement: (delta: number, element: any) => void = (delta, element) => {
  try {
    const contents: string = getCalendarYearContents(delta);

    element.innerHTML = contents;
  } catch (error) {
    console.error(error);
  }
};

const handleSetInitialCalendarYear: () => void = () => {
  try {
    const calendars: any = document.getElementsByClassName('calendarInput');

    for (let i = 0; i < calendars.length; i++) {
      const calendar: any = calendars[i];
      const yearInput: any = calendar?.getElementsByClassName('years')[0];

      setCalendarYearByDeltaAndElement(0, yearInput);
    }
  } catch (error) {
    console.error(error);
  }
};

const handleDecreaseCalendarYear: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearInput: any = parent?.getElementsByClassName('years')[0];
    const currentDelta: number = parseInt(yearInput?.dataset?.value || '0');
    const newDelta: number = currentDelta + 1;

    setCalendarYearByDeltaAndElement(newDelta, yearInput);
    yearInput.dataset.value = newDelta;
  } catch (error) {
    console.error(error);
  }
};

const handleIncreaseCalendarYear: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.calendarInput');
    const yearInput: any = parent?.getElementsByClassName('years')[0];
    const currentDelta: number = parseInt(yearInput?.dataset?.value || '0');
    const newDelta: number = currentDelta - 1 >= 0 ? currentDelta - 1 : 0;

    setCalendarYearByDeltaAndElement(newDelta, yearInput);
    yearInput.dataset.value = newDelta;
  } catch (error) {
    console.error(error);
  }
};

const getNumberOfDaysInMonthFromMonthAndYear: (month: number, year: number) => number = (month, year) => {
  try {
    const date: any = new Date(year, month, 1);

    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    return date.getDate();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const getFirstDayOfMonthFromMonthAndYear: (month: number, year: number) => number = (month, year) => {
  try {
    const date: any = new Date(year, month, 0);

    return date.getDay();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const relocateCalendarInputValueByIdToNewInputByIdAndReturnSuccess: (
  calendarInputID: string,
  newInputID: string
) => boolean = (calendarInputID, newInputID) => {
  try {
    const calendarInput: any = document.getElementById(calendarInputID);
    const newInput: any = document.getElementById(newInputID);
    const calendarInputValue: string = calendarInput?.value || '';

    newInput.value = calendarInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

window.addEventListener('load', () => {
  handleSetInitialCalendarYear();
});
